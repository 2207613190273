(function () {
    'use strict';

    angular.module('vouchr.searchModule')
        .directive('searchForm', ['searchService', 'configService', function(searchService, config){
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'templates/components/search/directives/search.form.directive.html',
                scope: {
                    searchTerm: '='
                },
                link: function(scope, elem, attrs) {
                    var initialSearch = _.trim(attrs.searchTerm, "'") || '';
                    var initialOpen = false;

                    scope.form = {
                        search: initialSearch,
                        open: initialOpen
                    };

                    scope.results = [];

                    function autocomplete() {
                        var params = angular.copy(scope.form.search);

                        if (params.length) {
                            searchService.autocomplete(params).then(function(results){
                                scope.results = results;
                            }, function(){
                                scope.results = [];
                            });
                        } else {
                            scope.results = [];
                        }
                    }

                    scope.doSearch = function(){
                        var s = scope.form.search.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
                        window.location.href = [config.get('app.url'), '?search=', s].join('');
                    };

                    scope.toggleSearch = function(){
                        scope.form.open = !scope.form.open;
                        if(scope.form.open){
                            // scope.form.search = '';
                            angular.element('#search_input').focus();
                        }
                    };

                    var firstLoad = true;
                    scope.$watch('form.search', _.debounce(function(){
                        if (!firstLoad) {
                            scope.$apply(function(){
                                autocomplete();
                            });
                        } else {
                            firstLoad = false;
                        }
                    }, 300));
                }
            };
        }]);
})();