(function () {
'use strict';

angular.module('vouchr.userModule').factory('userService', ['$q', 'apiAuthService', userService]);
    function userService($q, apiAuthService) {
        var service = {};

        service.login = function(params){
            return apiAuthService.login(params).then(function(result){
                return result.data;
            });
        };

        service.register = function(params){
            return apiAuthService.register(params).then(function(result){
                return result.data;
            });
        };

        service.update = function(params){
            return apiAuthService.update(params).then(function(result){
                return result.data;
                // return $q.resolve();
            });
        };

        service.getUserDetails = function(){
            return apiAuthService.getUserDetails().then(function(result){
                return result.data;
                // return $q.resolve();
            });
        };

        service.changePassword = function(){
            return $q.resolve();
        };

        return service;
    }
})();
