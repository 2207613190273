(function () {
    'use strict';

    angular.module('vouchr.brandModule')
        .directive('brandSubscribe', ['brandService', 'authService', function(brandService, authService){
            return {
                restrict: 'A',
                scope: {
                    brandSubscribe: '=',
                    brandName: '=',
                    brandLink: '='
                },
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        $event.preventDefault();

                        var elt = angular.element(document).find('.vouchr-page-main button.btn.btn-follow');
                        // console.log(elt.length);
                        if(elt.length > 0) {
                            element = elt;
                        }
                        element.addClass('loading');
                        element.prop('disabled', true);

                        authService.checkRegistration().then(function(user){
                            if (!element.hasClass('following')) {
                                return brandService.subscribe(scope.brandSubscribe).then(function(){
                                    element.addClass('following');
                                });
                            } else {
                                return brandService.unsubscribe(scope.brandSubscribe).then(function(){
                                    element.removeClass('following');
                                });
                            }
                        }).then(function(){
                            //skip modal window if brand-link attribute is false
                            if(scope.brandLink == 'include'){
                                commonModals.showBackdrop();
                                if (!element.hasClass('following')) {
                                    return commonModals.alertModal('Brand "' + scope.brandName + '" unsubscribed successfully.', 'Brand Unsubscribed').then(function(){
                                        commonModals.hideBackdrop();
                                    });
                                } else {
                                    return commonModals.alertModal('Brand "' + scope.brandName + '" subscribed successfully. Check out your account to browse all saved Brands!', 'Brand Subscribed').then(function(){
                                        commonModals.hideBackdrop();
                                    });
                                }
                            }else{
                                return true;
                            }
                        }).finally(function(){
                            element.prop('disabled', false);
                            element.removeClass('loading');
                        });
                    });
                }
            };
        }]);
})();