(function () {
    'use strict';

    angular.module('vouchr.locationModule')
        .directive('locationSelector', ['locationService', '$window', function(locationService, $window){
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    locationId: '='
                },
                templateUrl: 'templates/components/location/directives/location-selector.directive.html',
                link: function(scope, element, attrs, ctrl) {
                    var placeholderLocation = { name: attrs.placeholderText, id: 0 }
                    scope.selectedLocation = placeholderLocation;

                    scope.locations = [];
                    scope.buttonClass = attrs.buttonClass || 'btn-vouchr-select';

                    locationService.getLocationsForSelect().then(function(locations){
                        locations = _.concat(placeholderLocation, locations);
                        scope.locations = locations;
                    });

                    scope.$watch('locationId', function(newVal){
                        scope.selectedLocation = _.find(scope.locations, function(location){
                            return location.id == newVal;
                        });
                    });

                    scope.setLocation = function(location){
                        scope.locationId = location.id;
                        scope.selectedLocation = location;
                    };

                    scope.goToSelection = function(selection){
                        if(!angular.isUndefined(selection.internal_url)){
                            $window.location.href = selection.internal_url;
                        }
                    };
                }
            };
        }]);
})();