(function () {
'use strict';

angular.module('vouchr.apiModule').factory('apiSearchService', ['configService', '$httpParamSerializer', '$http', apiSearchService]);
    function apiSearchService(config, $httpParamSerializer, $http) {
        var service = {};

        service.autocomplete = function(params){
            params.debug_sql = 1;
            return $http.get(config.get('app.api_url_v2') + '/search/autocomplete?' + $httpParamSerializer(params));
        };

        return service;
    }
})();
