(function () {
'use strict';

angular.module('vouchr.authModule').factory('authService', ['$q', 'authModalService', '$timeout', '$facebook', 'apiAuthService', 'commonModals', authService]);
    function authService($q, authModalService, $timeout, $facebook, apiAuthService, commonModals) {

        var service = {};

        service.getUser = function(){
            return apiAuthService.getUser();
        };

        service.checkRegistration = function(){
            var deferred = $q.defer();
            var _self = this;

            // registration wizard
            _self.getUser().then(function(user){
                if (user) {
                    deferred.resolve(user);
                    return $q.reject();
                }

                return $q.resolve();
            }, function(){
                // login failed
                return $q.resolve();
            }).then(function(){
                // user not logged in, open auth dialog
                commonModals.showBackdrop();
                return authModalService.openLogin();
            }).then(function(result){
                // got result from login modal, process it
                if (result.authed) {
                    // User chose to login and did so successfully, resolve deferred promise and exit wizard
                    if(!result.completed){
                        return authModalService.openUpdateModal(result.user.data).then(function(){
                            deferred.resolve(result.user.data);
                            return $q.reject();
                        });
                    }
                    deferred.resolve(result.user.data);
                    return $q.reject();
                } else {
                    switch (result.type) {
                        case 'local':
                        default:
                            // User selected local registration - No form prefill
                            return {
                                username: '',
                                password: '',
                                type: '',
                                token: '',
                                state: 'NSW'
                            };
                            break;
                        case 'facebook':
                            // User selected facebook login, do facebook login and return facebook user
                            return _self.loginFacebook().then(function(response){
                                if (response.authed) {
                                    // user authed, resolve the user and quit wizard.

                                    if(!response.user.completed){
                                        return authModalService.openUpdateModal(response.user.data).then(function(user){
                                            deferred.resolve(user);
                                            return $q.reject();
                                        });
                                    }

                                    deferred.resolve(response.user);
                                    return $q.reject();
                                } else {
                                    // user not authed, but we can form prefill
                                    return {
                                        username: response.fbUser.email,
                                        password: response.fbUser.id,
                                        firstname: response.fbUser.first_name,
                                        lastname: response.fbUser.last_name,
                                        gender: response.fbUser.gender.charAt(0).toUpperCase() + response.fbUser.gender.slice(1),
                                        type: 'F',
                                        token: response.fbUser.authToken,
                                        state: 'NSW'
                                    };
                                }
                            });
                            break;
                    }
                }

                return $q.reject();
            }).then(function(formPrefill){
                // got our completed form prefill, open register modal with prefill data
                return authModalService.openRegisterModal(formPrefill);
            }).then(function(user){
                // user registered successfully, we have reached the end of the wizard
                // deferred promise gets a user object
                deferred.resolve(user);
            }).catch(function(err){
                // do nothing at this stage, they've exited the wizard/closed a dialog.
                // Hopefully the 'deferred' promise has been resolved by whatever rejected this promise chain :)
                // will probably be used to close manually opened dialog backdrop when I implement it
                commonModals.hideBackdrop();
                deferred.reject(err);
            });

            return deferred.promise;
        };

        service.checkLogin = function(){
            var deferred = $q.defer();
            var _self = this;

            // registration wizard
            _self.getUser().then(function(user){
                if (user) {
                    deferred.resolve(user);
                    return $q.reject();
                }

                return $q.resolve();
            }, function(){
                // login failed
                return $q.resolve();
            }).then(function(){
                // user not logged in, open auth dialog
                commonModals.showBackdrop();
                return authModalService.openLogin();
            }).then(function(result){
                // got result from login modal, process it
                if (result.authed) {
                    // User chose to login and did so successfully, resolve deferred promise and exit wizard
                    deferred.resolve(result.user);
                    return $q.reject();
                }else{
                    switch (result.type) {
                        case 'facebook':
                            // User selected facebook login, do facebook login and return facebook user
                            return _self.loginFacebook().then(function(response){
                                if (response.authed) {
                                    // user authed, resolve the user and quit wizard.
                                    deferred.resolve(response.user);
                                    return $q.reject();
                                } else {
                                    // user not authed, but we can form prefill
                                    return {
                                        username: response.fbUser.email,
                                        password: response.fbUser.id,
                                        firstname: response.fbUser.first_name,
                                        lastname: response.fbUser.last_name,
                                        gender: response.fbUser.gender.charAt(0).toUpperCase() + response.fbUser.gender.slice(1),
                                        type: 'F',
                                        token: response.fbUser.authToken,
                                        state: 'NSW'
                                    };
                                }
                            });
                            break;
                    }
                }

                return $q.reject();
            }).then(function(formPrefill){
                // got our completed form prefill, open register modal with prefill data
                return authModalService.openRegisterModal(formPrefill);
            }).then(function(user){
                // user registered successfully, we have reached the end of the wizard
                // deferred promise gets a user object
                deferred.resolve(user);
            }).catch(function(err){
                // do nothing at this stage, they've exited the wizard/closed a dialog.
                // Hopefully the 'deferred' promise has been resolved by whatever rejected this promise chain :)
                // will probably be used to close manually opened dialog backdrop when I implement it
                commonModals.hideBackdrop();
                deferred.reject(err);
            });

            return deferred.promise;
        };

        service.checkRegister = function(){
            var deferred = $q.defer();
            var _self = this;

            // registration wizard
            _self.getUser().then(function(user){
                if (user) {
                    deferred.resolve(user);
                    return $q.reject();
                }

                return $q.resolve();
            }, function(){
                // login failed forcing the exeption to the next step
                return $q.resolve();
            }).then(function(){
                return {
                    gender: '',
                    username: '',
                    password: '',
                    type: '',
                    token: '',
                    state: ''
                };

                return $q.reject();
            }).then(function(formPrefill){
                // got our completed form prefill, open register modal with prefill data
                return authModalService.openRegisterModal(formPrefill);
            }).then(function(user){
                // user registered successfully, we have reached the end of the wizard
                // deferred promise gets a user object
                deferred.resolve(user);
            }).catch(function(err){
                // do nothing at this stage, they've exited the wizard/closed a dialog.
                // Hopefully the 'deferred' promise has been resolved by whatever rejected this promise chain :)
                // will probably be used to close manually opened dialog backdrop when I implement it
                commonModals.hideBackdrop();
                deferred.reject(err);
            });

            return deferred.promise;
        };

        service.loginFacebook = function(){
            var deferred = $q.defer();
            var _self = this;

            $facebook.login().then(function(result){
                if (result.status == 'connected') {
                    return $facebook.api('/me', {fields: 'email,first_name,gender,last_name'}).then(function(fbUserDetails){
                        fbUserDetails.authToken = result.authResponse.accessToken;
                        return fbUserDetails;
                    });
                } else {
                    return $q.reject();
                }
            }).then(function(fbUserDetails){
                return _self.doFacebookAuth({ password: fbUserDetails.id, username: fbUserDetails.email, token: fbUserDetails.authToken }).then(function(user){
                    if (user) {
                        // user logged in successfully
                        deferred.resolve({ authed: true, user: user });
                    } else {
                        // something went... wrong... i'm not sure how you got here. the code shouldn't get here.
                        deferred.resolve({ authed: false, fbUser: fbUserDetails });
                    }
                }, function(err){
                    // login failed, register instead
                    deferred.resolve({ authed: false, fbUser: fbUserDetails });
                });
            }).catch(function(){
                deferred.reject(false);
            });

            return deferred.promise;
        };

        service.doFacebookAuth = function(params){
            params.type = 'F';

            return apiAuthService.login(params).then(function(result){
                return result.data;
            });
        };

        return service;

    }
})();
