(function () {
'use strict';

angular.module('vouchr.searchModule').factory('searchService', ['$q', 'apiSearchService', searchService]);
    function searchService($q, apiSearchService) {
        var service = {};

        service.autocomplete = function(search){
            return apiSearchService.autocomplete({ search: search }).then(function(result){
                return result.data;
            });
        };

        return service;
    }
})();
