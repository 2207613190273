(function () {
    'use strict';

    angular.module('vouchr.vouchrModule')
        .directive('vouchrSaveBtn', ['vouchrService', '$window', 'authService', 'commonModals', function(vouchrService, $window, authService, commonModals){
            return {
                restrict: 'A',
                scope: {
                    vouchrSaveBtn: '='
                },
                link: function(scope, element, attrs) {
                    element.on('click', function($event){
                        $event.preventDefault();

                        element.addClass('loading');
                        element.prop('disabled', true);

                        authService.checkRegistration().then(function(user){
                            return vouchrService.saveVouchr(scope.vouchrSaveBtn, 1).then(function(result){
                                if (result.success) {
                                    return result.data;
                                } else {
                                    commonModals.showBackdrop();
                                    return commonModals.alertModal("Couldn't save Vouchr, please try again later.", 'Error').then(function(){
                                        commonModals.hideBackdrop();
                                        return $q.reject();
                                    });
                                }
                            });
                        }).then(function(vouchr){
                            commonModals.showBackdrop();
                            return commonModals.alertModal('Vouchr "' + vouchr.line1 + '" saved successfully. Check out your account to browse all active saved Vouchrs!', 'Vouchr Saved').then(function(){
                                commonModals.hideBackdrop();
                            });
                        }).finally(function(){
                            element.prop('disabled', false);
                            element.removeClass('loading');
                        });
                    });
                }
            };
        }]);
})();