(function () {
    'use strict';

    angular.module('vouchr.authModule')
        .directive('openAuthRegisterDialog', ['authService', '$window', function(authService, $window){
            return {
                restrict: 'A',
                replace: false,
                scope: {},
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        $event.preventDefault();

                        authService.checkRegister().then(function(){
                            $window.location.href = '/register-thank-you';
                        });
                    });
                }
            };
        }]);
})();