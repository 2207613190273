(function () {
'use strict';

angular.module('vouchr.commonModule').factory('configService', ['$window', configService]);
    function configService ($window){
        var service = {
            _vars: $window.config,
            _flatVars: null,

            flattenConfig: function(ob){
                var toReturn = {};

                for (var i in ob) {
                    if (!ob.hasOwnProperty(i)) continue;

                    toReturn[i] = ob[i];

                    if ((typeof ob[i]) == 'object') {
                        var flatObject = this.flattenConfig(ob[i]);
                        for (var x in flatObject) {
                            if (!flatObject.hasOwnProperty(x)) continue;

                            toReturn[i + '.' + x] = flatObject[x];
                        }
                    }
                }
                return toReturn;
            },

            get: function(index, defaultVal){
                defaultVal = (typeof defaultVal !== 'undefined') ? defaultVal : null;
                index = (typeof index !== 'undefined') ? index : null;

                if (index !== null) {
                    return (typeof this._flatVars[index] !== undefined) ? this._flatVars[index] : defaultVal;
                } else {
                    return null;
                }
            },

            onload: function(){
                this._flatVars = this.flattenConfig(this._vars);
            }
        };

        service.onload();

        return service;
    }
})();
