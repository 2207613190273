(function () {
'use strict';

angular.module('vouchr.authModule').factory('authModalService', ['$q', '$uibModal', '$rootScope', 'userService', 'commonModals', '$filter', '$sce', authModalService]);
    function authModalService($q, $uibModal, $rootScope, userService, commonModals, $filter, $sce) {

        var service = {};

        service.openLogin = function(){
            var deferred = $q.defer();

            var loginModal = $uibModal.open({
                scope: $rootScope.$new(),
                windowClass: 'modal login',
                templateUrl: 'templates/components/auth/directives/login.modal.html',
                controllerAs: 'modalCtrl',
                backdrop: true,
                animation: true,
                resolve: {
                    ensureFacebookLoaded: function(){

                    }
                },
                controller: function($scope, ensureFacebookLoaded){
                    var modalCtrl = this;

                    modalCtrl.loginForm = {
                        username: '',
                        password: '',
                        reminder: false
                    };

                    modalCtrl.notification = {
                        text: ''
                    };

                    function setNotificationText(message) {
                        modalCtrl.notification.text = message;
                    }

                    modalCtrl.submitLoginForm = function() {
                        setNotificationText("");

                        userService.login(modalCtrl.loginForm).then(function(result){
                            $scope.$close({
                                authed: true,
                                user: result,
                                completed: result.completed
                            });
                        }, function(err){
                            if(err.data.facebook){
                                setNotificationText("You have signed up through Facebook, please check your Facebook account.");
                                modalCtrl.loginForm.reminder = true;
                            }else{
                                setNotificationText("Couldn't log in with the Email and Password provided.");
                                modalCtrl.loginForm.reminder = false;
                            }
                        });
                    };

                    modalCtrl.register = function(type){
                        $scope.$close({
                            authed: false,
                            action: 'register',
                            type: type
                        });
                    };
                }
            });

            loginModal.result.then(function(result){
                deferred.resolve(result);
            }, function(){
                deferred.reject();
            });

            return deferred.promise;
        };

        service.openRegisterModal = function(userPrefill){
            var deferred = $q.defer();

            var registerModal = $uibModal.open({
                scope: $rootScope.$new(),
                windowClass: 'modal register',
                templateUrl: 'templates/components/auth/directives/register.modal.html',
                controllerAs: 'modalCtrl',
                backdrop: true,
                animation: true,
                controller: function($scope){
                    var modalCtrl = this;

                    modalCtrl.notification = {
                        text: ''
                    };

                    modalCtrl.open_popup = function(){
                        modalCtrl.popup_opened = true;
                    };

                    function setNotificationText(message) {
                        if(angular.isArray(message)){
                            var error_html = '';
                            angular.forEach(message[0], function(value, key) {
                                error_html += key + ': ' + value + '<br>';
                            }, error_html);
                            modalCtrl.notification.text = $sce.trustAsHtml(error_html);
                        } else {
                            modalCtrl.notification.text = $sce.trustAsHtml(message);
                        }
                    }

                    if (userPrefill.username !== '') {
                        // if (_.isUndefined(userPrefill.state)) userPrefill.state = 'NSW';

                        modalCtrl.form = userPrefill;
                        modalCtrl.showPassword = false;
                    } else {
                        modalCtrl.form = {
                            username: '',
                            password: '',
                            type: '',
                            token: '',
                            gender: '',
                            state: ''
                        };

                        modalCtrl.showPassword = true;
                    }

                    function submitRegistration(params) {
                        var fData = angular.copy(params);

                        fData.dob = getDateasString(fData.dob);

                        userService.register(fData).then(function(result){
                            $scope.$close(result);
                        }, function(err){
                            setNotificationText(err.data.error);
                        });
                    };

                    function getDateasString(input) {
                        var dateAsString = $filter('date')(input, "dd/MM/yyyy");

                        return dateAsString;
                    }

                    modalCtrl.submit = function($valid){
                        modalCtrl.formSubmitted = true;

                        if($valid){
                            submitRegistration(modalCtrl.form);
                        }
                    };
                }
            });

            registerModal.result.then(function(result){
                deferred.resolve(result);
            }, function(){
                deferred.reject();
            });

            return deferred.promise;
        };

        service.openUpdateModal = function(userPrefill){
            var deferred = $q.defer();

            var updateModal = $uibModal.open({
                scope: $rootScope.$new(),
                windowClass: 'modal register',
                templateUrl: 'templates/components/auth/directives/update.modal.html',
                controllerAs: 'modalCtrl',
                backdrop: true,
                animation: true,
                controller: function($scope){
                    var modalCtrl = this;

                    modalCtrl.form = {};

                    modalCtrl.notification = {
                        text: ''
                    };

                    modalCtrl.open_popup = function(){
                        modalCtrl.popup_opened = true;
                    };

                    userService.getUserDetails().then(function(user){
                        modalCtrl.form = {
                            gender: user.gender,
                            firstname: user.firstName,
                            lastname: user.lastName,
                            dob: new Date(user.dob.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")),
                            phone: user.phone,
                            postcode: user.postcode
                        };
                    });

                    function setNotificationText(message) {
                        if(angular.isArray(message)){
                            var error_html = '';
                            angular.forEach(message[0], function(value, key) {
                                error_html += key + ': ' + value + '<br>';
                            }, error_html);
                            modalCtrl.notification.text = $sce.trustAsHtml(error_html);
                        } else {
                            modalCtrl.notification.text = $sce.trustAsHtml(message);
                        }
                    }

                    function submitUpdate(params) {
                        var fData = angular.copy(params);

                        fData.dob = getDateasString(fData.dob);

                        userService.update(fData).then(function(result){
                            $scope.$close(result);
                        }, function(err){
                            setNotificationText(err.data.error);
                        });
                    };

                    function getDateasString(input) {
                        var dateAsString = $filter('date')(input, "dd/MM/yyyy");

                        return dateAsString;
                    }

                    modalCtrl.submit = function($valid){
                        modalCtrl.formSubmitted = true;

                        if($valid){
                            submitUpdate(modalCtrl.form);
                        }
                    };
                }
            });

            updateModal.result.then(function(result){
                // deferred.resolve(result);
                window.location.href = '/register-thank-you';
            }, function(){
                // deferred.reject();
                deferred.resolve();
            });

            return deferred.promise;
        };


        service.changePasswordModal = function(){
            var passwordModal = $uibModal.open({
                scope: $rootScope.$new(),
                windowClass: 'modal change-password',
                templateUrl: 'templates/components/auth/directives/change-password-modal.modal.html',
                controllerAs: 'modalCtrl',
                backdrop: true,
                animation: true,
                size: 'sm',
                controller: function($scope){
                    var modalCtrl = this;

                    $scope.form = {
                        OldPassword: '',
                        NewPassword: ''
                    };

                    $scope.other = {
                        NewPasswordAgain: ''
                    };

                    modalCtrl.message = '';

                    function validate() {
                        if ($scope.form.OldPassword == '' || $scope.form.NewPassword == '' || $scope.other.NewPasswordAgain == '') {
                            return $q.reject('You must fill in all fields');
                        }

                        if ($scope.form.NewPassword !== $scope.other.NewPasswordAgain) {
                            return $q.reject('New Password must match New Password Again');
                        }

                        return $q.resolve();
                    };

                    function callServer() {
                        var params = angular.copy($scope.form);

                        userService.changePassword(params).then(function(result){
                            if (result.data.Success) {
                                return commonModals.alertModal('Password updated successfully.', 'Password Updated');
                            } else {
                                modalCtrl.message = result.data.Message;
                                return $q.reject();
                            }
                        }).then(function(){
                            $scope.$close();
                        });
                    }

                    modalCtrl.submit = function(){
                        validate().then(callServer, function(err){
                            modalCtrl.message = err;
                        });
                    };
                }
            });

            return passwordModal.result;
        };

        return service;

    }
})();
