(function () {
    'use strict';

    angular.module('vouchr.vouchrModule')
        .directive('vouchrUseBtn', ['vouchrService', '$window', 'authService', 'commonModals', function(vouchrService, $window, authService, commonModals){
            return {
                restrict: 'A',
                scope: {
                    vouchrUseBtn: '='
                },
                link: function(scope, element, attrs) {
                    element.on('click', function($event){
                        $event.preventDefault();

                        element.addClass('loading');
                        element.prop('disabled', true);

                    vouchrService.getVouchr(scope.vouchrUseBtn).then(function(result){
                        if (result.success) {
                            return result.data;
                        } else {
                            return commonModals.alertModal("Couldn't use Vouchr, please try again later.", 'Error').then(function(){
                                return $q.reject();
                            });
                        }
                    }).then(function(vouchr){
                        if (!_.isUndefined(vouchr.redeemInstructions)) {
                            if (_.startsWith(vouchr.redeemInstructions, 'rv:')) {
                                return vouchrService.launchVouchrUseModalRV(vouchr.redeemInstructions.substring(3), vouchr);
                            } else if (_.startsWith(vouchr.redeemInstructions, 'cc:')) {
                                var regex = /URL=(.*?),CODE=(.*)/g;
                                var matches = regex.exec(vouchr.redeemInstructions);

                                if (!_.isUndefined(matches[1]) && !_.isUndefined(matches[2])) {
                                    return vouchrService.launchVouchrUseModalCC(matches[1], matches[2], vouchr);
                                } else {
                                    return commonModals.alertModal("Couldn't use Vouchr, please try again later.", "Error").then(function(){
                                        return $q.reject();
                                    });
                                }
                            }
                        }

                        return $q.reject();
                    }).finally(function(){
                        element.prop('disabled', false);
                        element.removeClass('loading');
                    });
                });
            }
        };
    }]);
})();