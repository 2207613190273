(function () {
    'use strict';

    angular.module('vouchr.authModule', ['vouchr.apiModule', 'vouchr.templateModule', 'vouchr.commonModule', 'vouchr.userModule', 'ngFacebook'])
        .config(['$facebookProvider', function($facebookProvider){
            $facebookProvider.setCustomInit({
                appId      : config.vouchr.facebook_app_id,
                xfbml      : true,
                version    : 'v2.4'
            });
            $facebookProvider.setPermissions("email,public_profile");
        }]).run([function(){
            // Load the facebook SDK asynchronously
            (function(){
                // If we've already installed the SDK, we're done
                if (document.getElementById('facebook-jssdk')) {return;}

                // Get the first script element, which we'll use to find the parent node
                var firstScriptElement = document.getElementsByTagName('script')[0];

                // Create a new script element and set its id
                var facebookJS = document.createElement('script'); 
                facebookJS.id = 'facebook-jssdk';

                // Set the new script's source to the source of the Facebook JS SDK
                facebookJS.src = 'https://connect.facebook.net/en_US/sdk.js';

                // Insert the Facebook JS SDK into the DOM
                firstScriptElement.parentNode.insertBefore(facebookJS, firstScriptElement);
            }());
        }]);
})();