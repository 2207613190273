(function () {
    'use strict';

    angular.module('vouchr.passwordPage')
        .directive('clickedDisable', ['$window', function($window){
            return {
                restrict: 'A',
                replace: false,
                scope: {},
                link: function(scope, element, attrs) {
                    $(element).on('click', function(){
                        $(element).prop('disabled', true);
                        $( "#passwordForm" ).submit();
                    });
                }
            };
        }]);
})();
