(function () {
    'use strict';

    angular.module('vouchr.commonModule', ['vouchr.apiModule', 'vouchr.templateModule', 'ui.bootstrap', 'vouchr.searchModule'])
        .config(['$interpolateProvider', function($interpolateProvider){
            $interpolateProvider.startSymbol("<%");
            $interpolateProvider.endSymbol("%>");
        }]).run(['$window', 'commonModals', function($window, commonModals){
            $window.commonModals = commonModals;
        }]);
})();