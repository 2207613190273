(function () {
    'use strict';

    angular.module('vouchr.homePage', ['vouchr.vouchrModule', 'vouchr.authModule', 'ui.bootstrap'])
        .config(function($provide){
            /* rootscope event debugging
            $provide.decorator("$rootScope", function($delegate) {
            var Scope = $delegate.constructor;
            var origBroadcast = Scope.prototype.$broadcast;
            var origEmit = Scope.prototype.$emit;

            Scope.prototype.$broadcast = function() {
              console.log("$broadcast was called on $scope " + this.$id + " with arguments:",
                             arguments);
              return origBroadcast.apply(this, arguments);
            };
            Scope.prototype.$emit = function() {
              console.log("$emit was called on $scope " + this.$id + " with arguments:",
                             arguments);
              return origEmit.apply(this, arguments);
            };
            return $delegate;
            });
            */
        }).run([function(){
            var owl = angular.element('.owl-carousel');

            owl.owlCarousel({
                items: 4,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 3
                    },
                    992: {
                        items: 4
                    },
                    // 1200: {
                    //     items: 4
                    // }
                },
                // navContainer: '.owl-carousel',
                nav:true,
                navText: ['<span class="glyphicon glyphicon-menu-left"></span>', '<span class="glyphicon glyphicon-menu-right"></span>'],
                loop:true,
                responsiveClass:true
            });

            angular.element('.owl-carousel .owl-carousel-left').on('click', function($event){
                $event.preventDefault();
                owl.trigger('prev.owl.carousel');
            });

            angular.element('.owl-carousel .owl-carousel-right').on('click', function($event){
                $event.preventDefault();
                owl.trigger('next.owl.carousel');
            });
        }]);
})();