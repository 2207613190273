(function () {
'use strict';

angular.module('vouchr.vouchrModule').factory('vouchrService', ['$uibModal', '$rootScope', 'apiVouchrService', '$httpParamSerializer', vouchrService]);
    function vouchrService($uibModal, $rootScope, apiVouchrService, $httpParamSerializer) {
        var service = {
        	launchShareModal: function(shareUrl, attrs){
                var shareModal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal share',
                    templateUrl: 'templates/components/vouchr/directives/share.modal.html',
                    controllerAs: 'modalShareCtrl',
                    backdrop: true,
                    animation: true,
                    controller: function($scope){
                        var _self = this;

                        _self.shareUrl = shareUrl;
                        _self.shareFb = 'https://www.facebook.com/sharer.php?u='+shareUrl.href.substr(0, shareUrl.href.indexOf('#'));;
                        _self.shareTwitter = 'https://twitter.com/intent/tweet?url='+shareUrl+'&text='+attrs.line1+' '+attrs.line2+' '+attrs.line3;
                        _self.mailTo = 'mailto:?subject='+escape(attrs.line1)+' '+escape(attrs.line2)+' '+escape(attrs.line3)+'&body='+shareUrl;
                    }
                });

                return shareModal.result;
        	},
            mapFilterParameters: function(filter){
                return {
                    advertiser_id: filter.brandId,
                    category_id: filter.categoryId,
                    location_id: filter.locationId,
                    search: filter.search,
                    offset: filter.offset,
                    limit: filter.limit,
                    exclude_brand: filter.exclude_brand,
                    saved: filter.saved,
                    template_list: filter.template_list,
                    expired: filter.expired
                };
            },
            generateLoadMoreUrl: function(filter){
                var params = angular.copy(filter);
                params.offset += params.limit;
                return [window.location.protocol, '//', window.location.host, window.location.pathname].join('') + '?' + $httpParamSerializer(this.mapFilterParameters(params));
            },
            getVouchrListHtml: function(params){
                return apiVouchrService.getVouchrListHtml(params).then(function(response){
                    return response.data;
                });
            },
            saveVouchr: function(uuid, email){
                email = email || 0;

                return apiVouchrService.saveVouchr(uuid, { send_email: email }).then(function(response){
                    return response.data;
                });
            },
            getVouchr: function(uuid){
                return apiVouchrService.getVouchr(uuid).then(function(response){
                    return response.data;
                });
            },
            launchVouchrUseModalRV: function(useUrl, vouchr){
                var useModal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal use-rv',
                    templateUrl: 'templates/components/vouchr/directives/use-rv.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: true,
                    animation: true,
                    controller: function($scope){
                        var modalCtrl = this;
                        modalCtrl.useUrl = useUrl;
                        modalCtrl.vouchr = vouchr;
                    }
                });

                return useModal.result;
            },
            launchVouchrUseModalCC: function(useUrl, useCode, vouchr){
                var useModal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal use-cc',
                    templateUrl: 'templates/components/vouchr/directives/use-cc.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: true,
                    animation: true,
                    controller: function($scope){
                        var modalCtrl = this;
                        modalCtrl.useUrl = useUrl;
                        modalCtrl.useCode = useCode;
                        modalCtrl.vouchr = vouchr;
                    }
                });

                return useModal.result;
            }
        };

        return service;
    }
})();
