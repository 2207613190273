(function () {
    'use strict';

    angular.module('vouchr.authModule')
        .directive('openAuthLoginDialog', ['authService', '$window', function(authService, $window){
            return {
                restrict: 'A',
                replace: false,
                scope: {},
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        $event.preventDefault();

                        authService.checkLogin().then(function(){
                            $window.location.reload();
                        });
                    });
                }
            };
        }]);
})();