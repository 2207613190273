(function () {
'use strict';

angular.module('vouchr.locationModule').factory('locationService', ['$q', 'configService', locationService]);
    function locationService($q, config) {
        var service = {};

        service.getLocationsForSelect = function(params){
            return $q.resolve(config.get('vouchr.location_groups'));
        };

        return service;
    }
})();
