(function () {
'use strict';

angular.module('vouchr.categoryModule').factory('categoryService', ['$q', 'configService', categoryService]);
    function categoryService($q, config) {

        var service = {};

        service.getCategoriesForSelect = function(params){
            return $q.resolve(config.get('vouchr.categories'));
        };

        return service;
    }
})();
