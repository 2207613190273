(function () {
    'use strict';

    angular.module('vouchr.commonModule')
        .directive('openCommonDialog', ['commonModals', '$window', function(commonModals, $window){
            return {
                restrict: 'A',
                replace: false,
                scope: {},
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        $event.preventDefault();

                        if(attrs.show == 'privacy'){
                            commonModals.privacyModal();
                        }else{
                            commonModals.termsModal();
                        }
                    });
                }
            };
        }]);
})();