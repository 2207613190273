(function () {
'use strict';

angular.module('vouchr.commonModule').factory('commonModals', ['$uibModal', '$rootScope', '$q', commonModals]);
    function commonModals ($uibModal, $rootScope, $q){

        var service = {
            confirmModal: function(message){
                var confirmModal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal confirm',
                    templateUrl: 'templates/components/common/directives/confirm.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: true,
                    animation: true,
                    controller: function($scope){
                        var _self = this;

                        _self.message = message;
                    }
                });

                return confirmModal.result;
            },
            privacyModal: function(){
                var privacyModal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal privacy',
                    templateUrl: 'templates/components/common/directives/privacy.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: true,
                    animation: true,
                    controller: function($scope){
                        var _self = this;

                        // _self.message = message;
                    }
                });
            },
            termsModal: function(){
                var privacyModal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal terms',
                    templateUrl: 'templates/components/common/directives/terms.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: true,
                    animation: true,
                    controller: function($scope){
                        var _self = this;

                        // _self.message = message;
                    }
                });
            },
            alertModal: function(message, title){
                var deferred = $q.defer();
                title = title || '';

                var alertModal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal alert',
                    templateUrl: 'templates/components/common/directives/alert.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: true,
                    animation: true,
                    controller: function($scope){
                        var _self = this;

                        _self.message = message;
                        _self.title = title;
                    }
                });

                // ensures that promise resolves regardless of how the user closes the modal
                alertModal.result.then(function(){
                    deferred.resolve();
                }, function(){
                    deferred.resolve();
                });

                return deferred.promise;
            },
            showBackdrop: function(){
                angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);
            },
            hideBackdrop: function(){
                angular.element('.modal-backdrop').remove();
            }
        };

        return service;
    }
})();
