(function () {
'use strict';

angular.module('vouchr.apiModule').factory('apiBrandService', ['configService', '$httpParamSerializer', '$http', apiBrandService]);
    function apiBrandService(config, $httpParamSerializer, $http) {
        var service = {};

        service.subscribe = function(uuid){
            return $http.get(config.get('app.api_url_v2') + '/brand/' + uuid + '/subscribe');
        };

        service.unsubscribe = function(uuid){
            return $http.get(config.get('app.api_url_v2') + '/brand/' + uuid + '/unsubscribe');
        };

        return service;
    }
})();
