(function () {
    'use strict';

    angular.module('vouchr.brandModule')
        .directive('brandSelector', ['brandService', '$window', function(brandService, $window){
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    brandId: '='
                },
                templateUrl: 'templates/components/brand/directives/brand-selector.directive.html',
                link: function(scope, element, attrs, ctrl) {
                    var placeholderBrand = { name: attrs.placeholderText, id: 0 }
                    scope.selectedBrand = placeholderBrand;

                    scope.brands = [];
                    scope.buttonClass = attrs.buttonClass || 'btn-vouchr-select';

                    brandService.getBrandsForSelect().then(function(brands){
                        brands = _.concat(placeholderBrand, brands);
                        scope.brands = brands;
                    });

                    scope.$watch('brandId', function(newVal){
                        scope.selectedBrand = _.find(scope.brands, function(brand){
                            return brand.id == newVal;
                        });
                    });

                    scope.setBrand = function(brand){
                        scope.brandId = brand.id;
                        scope.selectedBrand = brand;
                    };

                    scope.goToBrand = function(brand){
                        if(!angular.isUndefined(brand.internal_url)){
                            $window.location.href = brand.internal_url;
                        }
                    };
                }
            };
        }]);
})();