(function () {
'use strict';

angular.module('vouchr.brandModule').factory('brandService', ['$q', 'configService', 'apiBrandService', brandService]);
    function brandService($q, config, apiBrandService) {

        var service = {};

        service.getBrandsForSelect = function(params){
            return $q.resolve(config.get('vouchr.brands'));
        };

        service.subscribe = function(uuid){
            return apiBrandService.subscribe(uuid).then(function(result){
                return result.data;
            });
        };

        service.unsubscribe = function(uuid){
            return apiBrandService.unsubscribe(uuid).then(function(result){
                return result.data;
            });
        };

        return service;
    }
})();
