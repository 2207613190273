(function () {
'use strict';

angular.module('vouchr.apiModule').factory('apiAuthService', ['configService', '$httpParamSerializer', '$http', apiAuthService]);
    function apiAuthService(config, $httpParamSerializer, $http) {
        var service = {};

        service.getUser = function(){
            return $http.get(config.get('app.api_url_v2') + '/auth/user');
        };

        service.login = function(params){
            return $http.post(config.get('app.api_url_v2') + '/auth/login', params);
        };

        service.register = function(params){
            return $http.post(config.get('app.api_url_v2') + '/auth/register', params);
        };

        service.update = function(params){
            return $http.post(config.get('app.api_url_v2') + '/auth/update', params);
        };

        service.getUserDetails = function(){
            return $http.get(config.get('app.api_url_v2') + '/auth/details');
        };

        service.logout = function(params){
            return $http.get(config.get('app.api_url_v2') + '/auth/logout');
        };

        return service;
    }
})();
