(function () {
'use strict';

angular.module('vouchr.apiModule').factory('apiVouchrService', ['configService', '$httpParamSerializer', '$http', apiVouchrService]);
    function apiVouchrService(config, $httpParamSerializer, $http) {
        var service = {};

        service.getVouchrList = function(params){
            return $http.get(config.get('app.api_url_v2') + '/vouchr?' + $httpParamSerializer(params));
        };

        service.getVouchrListHtml = function(params){
            return $http.get(config.get('app.api_url_v2') + '/vouchr/html?' + $httpParamSerializer(params));
        };

        service.saveVouchr = function(uuid, params){
            params = params || {};

            return $http.post(config.get('app.api_url_v2') + '/vouchr/' + uuid + '/save', params);
        };

        service.getVouchr = function(uuid){
            return $http.get(config.get('app.api_url_v2') + '/vouchr/' + uuid + '/details');
        };

        return service;
    }
})();
