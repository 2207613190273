(function () {
    'use strict';

    angular.module('vouchr.vouchrModule')
        .directive('vouchrList', ['vouchrService', 'searchService', 'configService', '$compile', '$httpParamSerializer', '$q', function(vouchrService, searchService, config, $compile, $httpParamSerializer, $q){
            return {
                restrict: 'A',
                replace: false,
                scope: {
                    initialOffset: '=?',
                    initialLimit: '=?',
                    initialBrand: '=?',
                    initialCategory: '=?',
                    initialLocation: '=?',
                    excludeBrand: '=?',
                    savedFilter: '=?',
                    templateList: '=?',
                    expired: '=?'
                },
                transclude: true,
                link: function(scope, element, attrs, ctrl, transclude) {
                    // Transclude so that we can edit DOM already existing inside the directive
                    transclude(scope, function (clone) {
                        element.append(clone);
                    });

                    var countRegex = /vouchr-list-container/ig;
                    var listContainer = element.find('.vouchr-list');
                    var moreButton = element.find('.vouchr-list-more');
                    var upButton = element.find('.list-filter-form .visible-xs .icon.up-icon');
                    var initialLimit = attrs.initialLimit || 6;
                    var initialOffset = attrs.initialOffset || 20;
                    var initialBrand = attrs.initialBrand || 0;
                    var initialCategory = attrs.initialCategory || 0;
                    var initialLocation = attrs.initialLocation || 0;
                    var excludeBrand = attrs.excludeBrand || 0;
                    var saved = attrs.savedFilter || 0;
                    var save_button = attrs.displaySaveButton || 0;
                    var template_list = attrs.templateList || 'vouchr-list-loop';
                    var expired = attrs.expired || 0;
                    var initialSearch = _.trim(attrs.searchTerm, "") || '';
                    var initialOpen = false;

                    initialOffset -= initialLimit;

                    scope.filter = {
                        brandId: initialBrand,
                        categoryId: initialCategory,
                        locationId: initialLocation,
                        search: initialSearch,
                        offset: initialOffset,
                        limit: initialLimit,
                        exclude_brand: excludeBrand,
                        saved: saved,
                        save_button: save_button,
                        template_list: template_list,
                        expired: expired,
                        open: initialOpen
                    };

                    scope.loading = false;

                    scope.isCollapsed = false;

                    upButton.bind('click', function() {
                        scope.isCollapsed = !scope.isCollapsed;
                        if(scope.isCollapsed == true){
                            upButton.addClass('rotated');
                        }else{
                            upButton.removeClass('rotated');
                        }
                        scope.$apply();
                        // console.log(scope.isCollapsed);
                    });

                    function resetVouchrs() {
                        listContainer.empty();
                        scope.filter.offset = 0;
                        moreButton.show();
                    }

                    function redrawMoreButton(filter) {
                        moreButton.show();
                        moreButton.attr('href', vouchrService.generateLoadMoreUrl(filter));
                    }

                    function callServer(reset) {
                        reset = reset || false;
                        if (reset) resetVouchrs();
                        scope.loading = true;

                        var params = vouchrService.mapFilterParameters(scope.filter);

                        return vouchrService.getVouchrListHtml(params).then(function(vouchrsHtml){
                            if (_.size(vouchrsHtml)) {
                                var newMarkup = $compile(vouchrsHtml)(scope);
                                listContainer.append(newMarkup);
                                if (vouchrsHtml.match(countRegex).length >= scope.filter.limit) {
                                    redrawMoreButton(scope.filter);
                                } else {
                                    moreButton.hide();
                                }
                            } else {
                                moreButton.hide();
                            }

                            scope.loading = false;

                            return vouchrsHtml;
                        }, function(err){
                            scope.loading = false;
                            return $q.reject(err);
                        });
                    }

                    function watchAction(newVal, oldVal) {
                        if (typeof newVal !== 'undefined' && newVal !== oldVal) {
                            callServer(true);
                        }
                    }

                    // scope.$watch('filter.brandId', watchAction);
                    // scope.$watch('filter.categoryId', watchAction);
                    // scope.$watch('filter.locationId', watchAction);


                    scope.results = [];

                    function autocomplete() {
                        var params = angular.copy(scope.filter.search);

                        if (params.length) {
                            searchService.autocomplete(params).then(function(results){
                                scope.results = results;
                            }, function(){
                                scope.results = [];
                            });
                        } else {
                            scope.results = [];
                        }
                    }

                    scope.doSearch = function(){
                        var s = scope.filter.search.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
                        window.location.href = [config.get('app.url'), '?search=', s].join('');
                    };

                    scope.toggleSearch = function(){
                        scope.filter.open = !scope.filter.open;
                        if(scope.filter.open){
                            // scope.filter.search = '';
                            angular.element('#search_input').focus();
                        }
                    };

                    var firstLoad = true;
                    scope.$watch('filter.search', _.debounce(function(){
                        if (!firstLoad) {
                            scope.$apply(function(){
                                autocomplete();
                            });
                        } else {
                            firstLoad = false;
                        }
                    }, 300));

                    scope.closeResults = function(){
                        scope.results = [];
                        scope.filter.search = '';
                    }

                    scope.submitFilters = function(){
                        callServer(true).then(function(vouchrsHtml){
                            // success, stuff already done... maybe nothing here.
                        }, function(err){
                            // what do if error?
                        });
                    };

                    scope.loadMore = function($event){
                        scope.loading = true; // trigger this a bit earlier in this instance to stop double clicks
                        $event.preventDefault();
                        scope.filter.offset = scope.filter.offset + scope.filter.limit;

                        callServer();
                    }

                    var scrollLoadMore = function() {
                        if ($(window).scrollTop() + window.innerHeight - $(document).height() + $('footer').height() > 100 && moreButton.is(":visible")) {
                            //setTimeout(callServer, 3000);
                            scope.filter.offset = scope.filter.offset + scope.filter.limit;
                            callServer();
                        }
                    }
                    $(window).scroll(_.throttle(scrollLoadMore, 500));

                    var $sidebar   = element.find(".list-filter-form"), 
                        $window    = $(window),
                        offset     = $sidebar.offset(),
                        topPadding = 60;

                    if($sidebar.length){
                        $window.scroll(_.throttle(function() {
                            if($window.width() > 991){
                                var marginTop = $window.scrollTop() - offset.top + topPadding;
                                // console.log($window.scrollTop());
                                // console.log($('main').height() - 160);
                                if ($window.scrollTop() > offset.top){
                                    if($window.scrollTop() <= $('main').height() - 160) {
                                        $sidebar.css({"transform":"translate(0, " + marginTop + "px)", "transition" : "transform 1s"});
                                    }
                                } else {
                                    $sidebar.css({"transform":"translate(0, 0px)", "transition" : "transform 1s"});
                                }
                            }else{
                                $sidebar.css({"transform":"none", "margin-top":"0px"});
                            }
                        }, 500));

                        $window.resize(_.throttle(function() {
                            if($window.width() <= 991){
                                $sidebar.css({"transform":"none", "margin-top":"0px"});
                            }
                        }, 500));
                    }
                }
            };
        }]);
})();