(function () {
    'use strict';

    angular.module('vouchr.vouchrModule')
        .directive('vouchrShareBtn', ['vouchrService','$window',  function(vouchrService, $window){
            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {
                    elem.bind('click', function() {

                        //call voucher service function
                        vouchrService.launchShareModal($window.location, attrs).then(function () {
                            // console.info('Modal OK closed at: ' + new Date());
                        }, function () {
                            // console.info('Modal dismissed at: ' + new Date());
                        });
                    });
                }
            };
        }]);
})();