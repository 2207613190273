(function () {
    'use strict';

    angular.module('vouchr.categoryModule')
        .directive('categorySelector', ['categoryService', '$window', function(categoryService, $window){
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    categoryId: '='
                },
                templateUrl: 'templates/components/category/directives/category-selector.directive.html',
                link: function(scope, element, attrs, ctrl) {
                    var placeholderCategory = { name: attrs.placeholderText, id: 0 }
                    scope.selectedCategory = placeholderCategory;

                    scope.categories = [];
                    scope.buttonClass = attrs.buttonClass || 'btn-vouchr-select';

                    categoryService.getCategoriesForSelect().then(function(categories){
                        categories = _.concat(placeholderCategory, categories);
                        scope.categories = categories;
                    });

                    scope.$watch('categoryId', function(newVal){
                        scope.selectedCategory = _.find(scope.categories, function(category){
                            return category.id == newVal;
                        });
                    });

                    scope.setCategory = function(category){
                        scope.categoryId = category.id;
                        scope.selectedCategory = category;
                    };

                    scope.goToCategory = function(category){
                        if(!angular.isUndefined(category.internal_url)){
                            $window.location.href = category.internal_url;
                        }
                    };
                }
            };
        }]);
})();